/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
/**
 * Basic button structural styling. Extend in your theme stylesheet.
 *
 * Demo: jsfiddle.net/inuitcss/RqHcp
 *
 */
.btn {
  -webkit-appearance: none;
  border: none;

  background-color: $color-button-regular;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $font-secondary;
  font-size: $size-epsilon;
  margin: 0;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  /**
   * Set padding in ems so we can change the size of a button by merely
   * altering its `font-size`.
   */
  padding: em($base-spacing-unit * 0.75, $base-font-size) em($base-spacing-unit * 3, $base-font-size);

  &:hover {
    background-color: $color-button-active;
    color: #fff;
    text-decoration: none;
    border: none;
  }
}


/**
 * Basic button modifiers
 */
.btn--sml {
  font-size: .75em;
}

.btn--lrg {
  font-size: 1.50em;
}

.btn--full {
  display: block;
  /**
   * The following is so that `.btn-full` works properly on `input`s as well
   * as `a`s.
   */
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}

.btn--outline {
  background-color: transparent;
  border: 1px solid $color-tertiary;

  &:hover {
    background-color: transparent;
    border: 1px solid $color-tertiary-shaded;
  }
}
