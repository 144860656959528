/*------------------------------------*\
    $HEADINGS
\*------------------------------------*/
/**
 * As per: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 *
 * When we define a heading we also define a corresponding class to go with it.
 * This allows us to apply, say, `class=alpha` to a `h3`; a double-stranded
 * heading hierarchy.
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.1;
}

h1 {
  font-size: em($size-kilo, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-kilo);
}

h2 {
  font-size: em($size-beta, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-beta);
}

h3 {
  font-size: em($size-gamma, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-gamma);
}

h4 {
  font-size: em($size-delta, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-delta);
}

h5 {
  font-size: em($size-epsilon, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-epsilon);
}

h6 {
  font-size: em($size-zeta, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-zeta);
}
