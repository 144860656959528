/*------------------------------------*\
    $IMAGES
\*------------------------------------*/
/**
 * Demo: jsfiddle.net/inuitcss/yMtur
 */
img {
  @if $flexible-media == true {
    max-width: 100%;
    height: auto;
  }
}


/**
 * Image placement variations.
 */
.img--right,
.alignright {
  float: right;
  margin-bottom: $base-spacing-unit;
  margin-left: $base-spacing-unit;
}

.img--left,
.alignleft {
  float: left;
  margin-right: $base-spacing-unit;
  margin-bottom: $base-spacing-unit;
}

.img--center,
.aligncenter {
  display: block;
  margin-right: auto;
  margin-bottom: $base-spacing-unit;
  margin-left: auto;
}

.alignnone {
  display: inline;
  float: none;
  margin: 0;
}


/**
 * Keep your images on your baseline.
 *
 * Please note, these will not work too nicely with fluid images and will
 * distort when resized below a certain width. Use with caution.
 */
.img--short {
  height: 5 * $base-spacing-unit;
}
.img--medium {
    height: 10 * $base-spacing-unit;
}
.img--tall {
    height: 15 * $base-spacing-unit;
}


/**
 * Images in `figure` elements.
 */
figure > img {
    display: block;
}
