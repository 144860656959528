.site-footer {
  background-color: $color-secondary;
  color: #fff;
  padding-bottom: $base-spacing-unit;
  padding-top: $base-spacing-unit;

  @media screen and (min-width: $lap-start) {
    padding-bottom: $base-spacing-unit * 1.5;
    padding-top: $base-spacing-unit * 1.5;
  }

  .home & {
    background-color: rgba( shade( $color-secondary, 20 ), 0.6 );
  }

  .home & {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  .legal {
    font-family: $font-secondary;
    font-size: 10px;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    @media screen and (min-width: $lap-start) {
      font-size: 12px;
    }
  }
}
