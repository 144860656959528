/*------------------------------------*\
    $QUOTES
\*------------------------------------*/
/**
 * If English quotes are set in `_vars.scss`, define them here.
 */
@if $english-quotes == true {
  $open-quote: “ !global;
  $close-quote: ” !global;
}


/**
 * Big up @boblet: html5doctor.com/blockquote-q-cite
 */

/**
 * Inline quotes.
 */
q {
  quotes: "‘" "’" "#{$open-quote}" "#{$close-quote}";

  &:before {
    content: "‘";
    content: open-quote;
  }

  &:after {
    content: "’";
    content: close-quote;
  }

  q:before {
    content: "“";
    content: open-quote;
  }

  q:after {
    content: "”";
    content: close-quote;
  }
}

blockquote {
  quotes: "#{$open-quote}" "#{$close-quote}";
  font-style: italic;
  /**
   * .4em is roughly equal to the width of the opening “ that we wish to hang.
   */
  text-indent: -0.41em;

  p:before {
    content: "#{$open-quote}";
    content: open-quote;
  }

  p:after {
    content: "";
    content: no-close-quote;
  }

  p:last-of-type {
    margin-bottom:0;

    &:after {
      content: "#{$close-quote}";
      content: close-quote;
    }
  }

  q:before {
    content: "‘";
    content: open-quote;
  }

  q:after {
    content: "’";
    content: close-quote;
  }

  footer {
    font-weight: 700;
  }
}

.source {
  font-style: italic;
  font-weight: 700;
  text-indent: 0;

  &:before {
    content: "— ";
  }
}
