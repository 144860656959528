/*------------------------------------*\
    $MIXINS
\*------------------------------------*/
/**
 * Create a fully formed type style (sizing and vertical rhythm) by passing in a
 * single value, e.g.:
 *
   `@include font-size(10px);`
 *
 * Thanks to @redclov3r for the `line-height` Sass:
 * twitter.com/redclov3r/status/250301539321798657
 */
@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size) * 1rem;
  line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
}


/**
 * Better Image Replacement
 */
@mixin hidden-text {
position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    // `clip` is deprecated, but retained for safety in making sure that this
    // utility works as expected for screenreaders. Comma-separated syntax is
    // not used because space-separated is more backward-compatible,
    // per https://developer.mozilla.org/en-US/docs/Web/CSS/clip
    clip: rect(0 0 0 0);
}


/**
 * Stylable Ordered Lists
 *
 * Prefixes each item w/ a counter based on all items in the parent
 */
@mixin cust-list {
  counter-reset: item;
  list-style-type: none;

  & > li:before {
    content: counter(item) ". ";
    counter-increment: item;
  }
}


/**
 * Styles the counter to act more like an order list ie. hanging punctuation
 */
@mixin hang-list {
  counter-reset: item;
  list-style-type: none;
  padding-left: 3.25em;

  & > li {
    position: relative;

    &:before {
      content: counter(item);
      counter-increment: item;
      left: -2em;
      position: absolute;
      text-align: right;
      width: 1.625em;
    }
  }
}
