/*!
* Theme Name: Evolved Default
* Description: The default Evolved theme. All CSS is compiled from SCSS files, do not edit this file. Project utilizes Bourbon for prebuilt mixins and Neat for the grid.
* Author: WP-Evolved
* Author URI: https://github.com/wp-evolved/
* version: 1.0.0
*/


/**
 * Bourbon
 */
@import "bourbon";


/**
 * Project Settings
 */
@import "settings";


/**
 * Neat
 */
@import "neat";


/**
 * Project Generic
 */
@import "generic/mixins";
@import "generic/normalize";
@import "generic/reset";
@import "generic/clearfix";
@import "generic/shared";
//@import "generic/helper";


/**
 * Project Base
 */
@import "base/main";
@import "base/headings";
@import "base/paragraphs";
@import "base/links";
@import "base/smallprint";
@import "base/quotes";
//@import "base/code";
@import "base/images";
@import "base/lists";
//@import "base/tables";
@import "base/forms";


/**
 * Project Objects
 */
//@import "objects/nav";
//@import "objects/pagination";
//@import "objects/breadcrumb";
//@import "objects/media";
//@import "objects/island";
//@import "objects/block-list";
//@import "objects/matrix";
//@import "objects/split";
//@import "objects/link-complex";
//@import "objects/flyout";
//@import "objects/sprite";
@import "objects/buttons";
//@import "objects/rules";
//@import "objects/footnotes";
//@import "objects/icon-font";


/**
 * Project Modules
 */
@import "modules/site-header";
@import "modules/site-footer";
@import "modules/global";
@import "modules/post-preview";
@import "modules/home";
@import "modules/store";


/**
 * Project Layouts
 */


/**
 * Shame Styles **THESE SHOULD BE TEMPORARY**
 */
@import 'shame';


/**
 * Debug Styles **ONLY FOR DEVELOPMENT**
 */
@import "generic/debug";
