

.is-hidden {
  @include hidden-text();
}

.profile-avatar {
  border-radius: 50%;
  display: block;
}

.legal {
  font-size: em($size-zeta, $base-font-size);
  margin-bottom: 0;
  text-align: center;
}

.featured-image {
  margin: ($base-spacing-unit * -3)
          ($base-spacing-unit * -1)
          ($base-spacing-unit * 2);
  @media screen and (min-width: $lap-start) {
    margin-left: ($base-spacing-unit * -2);
    margin-right: ($base-spacing-unit * -2);
    margin-bottom: ($base-spacing-unit * 3);
  }

  @media screen and (min-width: $desk-start) {
    margin-left: ($base-spacing-unit * -3);
    margin-right: ($base-spacing-unit * -3);
  }
}

.page-header {
  margin-bottom: em($base-spacing-unit * 3, $base-font-size);

  .profile-avatar {
    width: 250px;
    margin: em($base-spacing-unit * 2)
            auto
            0;
  }

  @media screen and (min-width: $lap-start) {
    margin-bottom: em($base-spacing-unit * 4, $base-font-size);

    .profile-avatar {
      width: 400px;
      margin: em($base-spacing-unit * 3)
              auto
              0;
    }
  }
}

.post-header {
  max-width: em(700px, $base-font-size);
  margin: 0 auto em($base-spacing-unit * 3, $base-font-size);
}

.page-title,
.post-title {
  font-family: $font-secondary;
  font-size: em($size-delta, $base-font-size);
  letter-spacing: 0.24em;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: $lap-start) {
    font-size: em($size-gamma, $base-font-size);
  }
}

.post-title {
  margin-bottom: em($base-spacing-unit, $size-gamma);
}

.post-meta {
  color: tint($color-secondary, 25%);
  font-size: em($size-epsilon, $base-font-size);
  margin-bottom: em($base-spacing-unit, $size-epsilon);
  text-align: center;
}

.post-sub-title {
  font-family: $font-secondary;
  font-size: em($size-epsilon, $base-font-size);
  font-weight: 300;
  text-align: center;

  @media screen and (min-width: $lap-start) {
    font-size: em($size-delta, $base-font-size);
  }


  &:after {
    border-bottom: 1px solid tint($color-primary, 75%);
    content: '';
    display: block;
    margin: 30px auto 0;
    width: 100px;
  }

  @media screen and (min-width: $wall-start) {
    font-size: $size-delta;

    &:after {
      width: 130px;
    }
  }
}

.post-content,
.page-content {
  max-width: em(700px, $base-font-size);
  margin: 0 auto;

  @media screen and (min-width: $lap-start) {
    p {
      font-size: em($size-gamma, $base-font-size);
    }
  }
}

.entry-content:after {
  border-bottom: 1px solid tint($color-primary, 75%);
  content: '';
  display: block;
  margin: 60px auto 0;
  width: 160px;
}

.post-pagination {
  @extend %cf;

  @include grid-column(8);
  @include grid-shift(2);

  font-family: $font-secondary;
  font-size: 22px;
  font-weight: 600;
  margin-top: 80px;

  .prev {
    @include grid-column(2 of 8);
  }

  .next {
    @include grid-column(2 of 8);
    text-align: right;
  }

  .home {
    @include grid-column(4 of 8);

    text-align: center;
  }
}

.contact-form {
  @include grid-container;
  @include grid-collapse;

  font-family: $font-secondary;
  font-size: $size-epsilon;
  margin-top: $base-spacing-unit * 3;

  @include grid-media($neat-grid-lap) {
    @include grid-collapse;
  }

  @include grid-media($neat-grid-desk) {
    @include grid-collapse;
  }

  .form-fields {
    @extend %cf;
  }

  @media screen and (max-width: $lap-end) {
    clear: both;
  }

  form {
    background-color: shade($color-background, 1%);
    border: 1px solid $color-bg-tinted;
    padding: $base-spacing-unit * 2;

    @include grid-column(12);

    @include grid-media($neat-grid-lap) {
      @include grid-column(10);
      @include grid-shift(1);
    }

    @include grid-media($neat-grid-desk) {
      @include grid-column(8);
      @include grid-shift(2);
    }
  }

  h3 {
    font-size: $size-alpha;
    text-align: center;
  }

  label {
    font-weight: 600;
    font-size: $size-milli;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  input:not(.submit),
  textarea {
    -webkit-appearance: none;
    background-color: tint($color-background, 40%);
    border: 1px solid $color-bg-tinted;

    &:focus {
      background-color: #fff;
      outline: 1px solid $color-tertiary;
      border-color: $color-tertiary;
    }
  }

  .submit {
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: $wall-start) {
    .form-fields {
      @include grid-container;
      @include grid-collapse;
    }

    #foli8,
    #foli3 {
      @include grid-column(3 of 6);
    }

    #foli4 {
      @include grid-column(1 of 1);

      textarea {
        height: 140px;
      }
    }

    .buttons {
      clear: both;
    }
  }
}

@mixin flexible-container( $width: 16, $height: 9 ) {
  $ratio: ( $height / $width ) * 100;

  position: relative;
  padding-bottom: $ratio * 1%;
  height: 0;
}

.video-embed {
  @include flexible-container(640, 360);
}

.video-embed__inner {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.post a {
  overflow-wrap: break-word;
}

.link-five-hundred {
  border-bottom: none !important;
}

.carousel img {
  display: block;
  max-height: 85vh;
}

.carousel--portfolio img {
  height: 62vw;

  @media screen and (min-width: $lap-start) {
    height: 60vw;
  }
}

.carousel--story {
  img {
    height: 50vw;
  }

  .post-content + & {
    margin-top: $base-spacing-unit * 3;
  }

  + .smallprint {
    margin-top: $base-spacing-unit;
    text-align: center;
  }
}
