/*------------------------------------*\
    $SETTINGS
\*------------------------------------*/
/**
 * Set up project-specific variables here which are then used throughout your
 * build. Many options are based on Inuit.css
 */


/**
 * Debug mode will visually highlight any potential markup/accessibility quirks
 * in the browser. Set to `true` or `false`.
 */
$debug-mode:  false!default;


/**
 * Base Sizes
 */
$base-font-size:    20px!default;
$base-line-height:  28px!default;
$base-spacing-unit: 20px!default;
$half-spacing-unit: $base-spacing-unit / 2!default;
$line-height-ratio: $base-line-height / $base-font-size!default;


/**
 * Set the border radius
 */
$global-round: 4px!default;


/**
 * Flexible Media (Important for responsive sites)
 */
$flexible-media:  true!default;


/**
 * Set the breakpoints (set these to where your content breaks, not to specific screen sizes)
 */
$lap-start:   700px!default;
$desk-start:  1060px!default;
$wall-start:  1200px!default;
$palm-end:    $lap-start - 1px!default;
$lap-end:     $desk-start - 1px!default;
$desk-end:    $wall-start - 1px!default;


/**
 * Neat Grid Settings
 */
$border-box-sizing:   true!default;
$column:              70px!default;
$grid-columns:        12!default;
$gutter:              $base-spacing-unit * 3!default;
$max-width:           1400px!default;
$visual-grid:         true!default;
$visual-grid-color:   yellow!default;
$visual-grid-index:   front!default;
$visual-grid-opacity: 0.5!default;

$neat-grid: (
  columns: $grid-columns,
  gutter: $base-spacing-unit,
  color: $visual-grid-color
);

$neat-grid-lap: (
  columns: $grid-columns,
  gutter: $base-spacing-unit * 2,
  media: "screen and (min-width: $lap-start)"
);

$neat-grid-desk: (
  columns: $grid-columns,
  gutter: $base-spacing-unit * 3,
  media: "screen and (min-width: $desk-start)"
);

$neat-grid-projects: (
  columns: 1,
  gutter: 0,
  media: screen and all
);

$neat-grid-projects-lap: (
  columns: 2,
  gutter: 0,
  media: "screen and (min-width: $lap-start)"
);

$neat-grid-projects-desk: (
  columns: 3,
  gutter: 0,
  media: "screen and (min-width: $desk-start)"
);

/**
 * Font-sizes (in pixels). Refer to relevant sections for their implementations.
 */
$size-giga:     96px;
$size-mega:     64px;
$size-kilo:     44px; // h1-size

$size-alpha:    36px;
$size-beta:     30px; // h2-size
$size-gamma:    26px; // h3-size
$size-delta:    20px; // h4-size
$size-epsilon:  18px; // h5-site
$size-zeta:     15px; // h6-size

$size-milli:    13px;
$size-micro:    12px;


/**
 * English quote marks?
 */
$english-quotes:  true!default;


/**
 * If you want English quotes then please do not edit these; they’re only here
 * because Sass needs them.
 */
$open-quote:  null;
$close-quote: null;


/**
 * If you need non-English quotes, please alter the following values accordingly:
 */
@if $english-quotes != true {
  $open-quote:  «;
  $close-quote: »;
}


/**
 * Typography
 */
$font-primary:    'Crimson Text', Georgia, serif;
$font-secondary:  'Lato', 'Open Sans', 'Helvetica Neue', sans-serif;


/**
 * Color Variables
 */
$color-primary:         #25323d;
$color-secondary:       #10273e; //#0f2d4a; //#0c3257;
$color-tertiary:        #c93b2b;
$color-tertiary-shaded: #b52919; //#911f12;
$color-background:      #ffffff;
$color-bg-tinted:       shade($color-background, 15%);
$color-text:            $color-primary;
$color-text-required:   #b1b3b6;
$color-text-tinted:     #9090aa;
$color-bg-header:       #555;
$color-bg-nav:          #f3f6fb;
$color-bg-footer:       #555;
$color-bg-card:         #f5f4ef;
$color-bg-card-shaded:  shade(#F5F4EF, 10%);
$color-link-regular:    $color-tertiary;
$color-link-active:     $color-tertiary-shaded;
$color-button-regular:  $color-tertiary;
$color-button-active:   $color-tertiary-shaded;

$color-brand-twitter:   #2cbfed;
$color-brand-facebook:  #3a5898;
$color-brand-gplus:     #db4a39;
$color-mail:            #1c1c1c;

