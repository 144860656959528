/*------------------------------------*\
    $PARAGRAPHS
\*------------------------------------*/
/**
 * The `.lede` class is used to make the introductory text (usually a paragraph)
 * of a document slightly larger.
 */
.lede,
.lead {
  font-size: em($base-font-size * 1.125, $base-font-size);
  margin-bottom: em($base-spacing-unit, $base-font-size * 1.125);
}
