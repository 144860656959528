.site-header {
  @extend %cf;

  border-top: 4px solid $color-tertiary;
  background-color: $color-secondary;
  font-family: $font-secondary;
  font-size: 12px;
  letter-spacing: 0.24em;
  padding-bottom: $half-spacing-unit;
  padding-top: $half-spacing-unit;
  text-transform: uppercase;

  .home & {
    background-color: rgba( $color-secondary, 0.5 );
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.site-logo {
  @media screen and (min-width: $lap-start){
    float: left;
  }

  @media screen and (max-width: $palm-end) {
    display: block;
    width: 40px;
    margin: 0 auto;

    .site-logo_text {
      @include hidden-text();
    }
  }

  &:hover {
    border: none;

    .site-logo_text {
      border-bottom: 2px solid #fff;
    }
  }

  .profile-avatar {
    display: inline-block;
    height: $base-spacing-unit * 2;
    width: $base-spacing-unit * 2;

    @media screen and (min-width: $lap-start) {
      margin-right: $base-spacing-unit;
      vertical-align: middle;
    }
  }
}

.site-nav {
  @media screen and (min-width: $lap-start) {
    float: right;
  }
}

.site-nav-list {
  text-align: center;

  @media screen and (min-width: $lap-start) {
    float: right;
    padding: $half-spacing-unit + 3px $half-spacing-unit;
  }

  li {
    display: inline-block;

    &:not(:first-child) {
      //margin-left: $base-spacing-unit;

      @media screen and (min-width: $lap-start) {
        margin-left: $base-spacing-unit * 2;
      }
    }

    @media screen and (max-width: $palm-end) {
      padding: $half-spacing-unit 0;
      min-width: 7.833333333em;
    }
  }

  a:hover {
    border-bottom: 2px solid #fff;
  }
}
