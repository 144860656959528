/*------------------------------------*\
    $LISTS
\*------------------------------------*/
/**
 * Remove vertical spacing from nested lists.
 */
li {
  > ul,
  > ol {
     margin-bottom: 0;
   }
}

/**
 * Have a numbered `ul` without the semantics implied by using an `ol`.
 */
.numbered-list {
  list-style-type: decimal;
}

/**
 * Remove default list styles
 */

%unstyled-list,
.unstyled-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
